<template>
    <div>
      <page-layout :show-home="false">
        <div class="main">
          <van-grid clickable :column-num="3" icon-size="28px">
            <van-grid-item icon="filter-o" text="信贷建议" to="/" />
            <van-grid-item icon="upgrade" text="征信助手" to="/" />
            <van-grid-item icon="points" text="发票采集" to="/miniapp/tool/invoice-collection" />
            <van-grid-item icon="chart-trending-o" text="LPR利率" url="/vant/mobile.html" />
          </van-grid>
        </div>
      </page-layout>
      <jzsy-tabbar></jzsy-tabbar>
    </div>
</template>

<script>
import { Grid, GridItem } from 'vant';
export default {
  name: "WebappTool",
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  data: function () {
    return {
      formData: {
        entName: "",
        contactPerson: "",
        phone: "",
        gender: "",
        goodsId: "无",
        goodsTypeId: "ACCOUNT_OPEN"
      },
      enterprise: {
        enterpriseName: "",
      },
      genderName: "",
      querying: false,
      popup: {
        show: false,
        message: "",
      },
    };
  },
  watch: {
    enterprise: {
      deep: true,
      handler(newVal) {
        this.formData.entName = newVal.enterpriseName;
      },
    },
    genderName(newVal) {
      if (newVal == '先生') {
        this.formData.gender = 1;
      } else if (newVal == '女士') {
        this.formData.gender = 2;
      }
    },
  },
  computed: {
    submitBtn() {
      return this.querying ? "提交中" : "提交申请";
    },
  },
  methods: {
    
  },
};
</script>

<style scoped>
.submit-btn {
  margin-top: 50px;
}
.main {
  width: 100%;
  margin: 0 auto;
}
.popup-message {
  padding: 30px 0;
  color: #ffffff;
  background-color: #ff976a;
  font-size: 36px;
  text-align: center;
}
</style>